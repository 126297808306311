import request from "@/core/services/axios";
export function studentlistpage(params) {
  // 学生榜单分页查询
  return request({
    url: `/report/statstudentreport/studentlistpage`,
    method: "post",
    data: params,
  });
}
export function getstudentreportstudentlisttopinfo(params) {
  // 获取学生榜单顶部数据
  return request({
    url: `/report/statstudentreport/getstudentreportstudentlisttopinfo`,
    method: "get",
    params: params,
  });
}

export function getsrscoreoverview(params) {
  // 学生报告-成绩概述
  return request({
    url: `/report/statstudentreport/getsrscoreoverview`,
    method: "get",
    params: params,
  });
}
export function getsrweak(params) {
  // 学生报告-优劣势学科
  return request({
    url: `/report/statstudentreport/getsrweak`,
    method: "get",
    params: params,
  });
}
export function getsrlearningtrack(params) {
  // 学生报告-学情跟踪
  return request({
    url: `/report/statstudentreport/getsrlearningtrack/v2`,
    method: "post",
    data: params,
  });
}
export function getsrknowledge(params) {
  // 学生报告-知识点
  return request({
    url: `/report/statstudentreport/getsrknowledge`,
    method: "get",
    params: params,
  });
}
export function getsrquestion(params) {
  // 学生报告-题目应答情况
  return request({
    url: `/report/statstudentreport/getsrquestion`,
    method: "get",
    params: params,
  });
}
export function getstudentreporttopinfo(params) {
  // 获取学生报告顶部数据
  return request({
    url: `/report/statstudentreport/getstudentreporttopinfo`,
    method: "get",
    params: params,
  });
}
export function exportstudentscore(params) {
  // 获取学生报告顶部数据
  return request({
    url: `/report/statstudentreport/exportstudentscore`,
    method: "get",
    params: params,
  });
}
export function exportStudentReport(params) {
  // 学生报告-导出
  return request({
    url: `/report/statstudentreport/export`,
    method: "post",
    data: params,
  });
}
export function exportStudentReportNew(params) {
  // 学生报告-导出-新高考
  return request({
    url: `/report/statnewgaokaostudentreport/export`,
    method: "post",
    data: params,
  });
}
