<template>
  <div ref="bar" :style="{ width: '100%', height: height }"></div>
</template>
<script>
import * as echarts from "echarts";
export default {
  name: "Bar",
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    height: {
      type: String || Number,
      default: "400px",
    },
  },
  data() {
    return {
      option: {},
    };
  },

  mounted() {
    this.$nextTick(() => {
      this.initChart(this.data);
    });
  },
  methods: {
    initChart(val) {
      let charts = echarts.getInstanceByDom(this.$refs.bar);
      if (!charts) {
        charts = echarts.init(this.$refs.bar, null, { devicePixelRatio: 2.5 });
      }
      this.option = { ...this.option, ...val };
      charts.setOption(this.option, true);
      setTimeout(() => {
        window.onresize = () => {
          charts.resize();
        };
      }, 100);
    },
  },
};
</script>
<style lang="scss" scoped></style>
