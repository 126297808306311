<template>
  <div
    ref="kownlagePoint"
    v-lazyChart="{ fn: getKnowledgeData }"
    class="container"
  >
    <div class="sub-title">知识点</div>
    <radar :key="'radar' + tokenKey" :data="options" height="400px" />
    <a-table
      bordered
      :pagination="false"
      :columns="columns"
      :data-source="tableData"
      style="margin: 18px 0"
    ></a-table>
    <!-- <div class="tip">
      注：标红的表格表示学生得分率低于班级得分率（或校得分率或整体得分率）；标红的题号表示学生该题应答相对较差
    </div> -->
  </div>
</template>

<script>
import radar from "./components/radar.vue";
import { getsrknowledge } from "@/core/api/newExamAcademic/student";
import { columnbyParams } from "../../func";
export default {
  name: "",
  components: {
    radar,
  },
  props: {
    subjectId: {
      type: [Number, String],
      default: "",
    },
    topData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      options: {},
      tokenKey: Math.random(),
      columns: [],
      tableData: [],
      rateCols: [
        {
          title: "得分率（班级）",
          dataIndex: "classScoreRate",
          align: "center",
          customRender: (text, record) => record.classScoreRate + "%",
        },
        {
          title: "得分率（年级）",
          dataIndex: "gradeScoreRate",
          align: "center",
          customRender: (text, record) => record.gradeScoreRate + "%",
        },
        {
          title: "得分率（整体）",
          dataIndex: "unionScoreRate",
          align: "center",
          customRender: (text, record) => record.unionScoreRate + "%",
        },
      ],
    };
  },
  created() {},
  methods: {
    async getKnowledgeData(loadingInstance, io) {
      const res = await getsrknowledge({
        id: this.$route.query.id,
        studentId: this.$route.query.studentId,
        subjectId: this.subjectId,
      }).catch(() => {
        this.closeLoading(loadingInstance, io);
      });
      let cols = [];
      if (this.$route.query.examType == 1) {
        cols = columnbyParams(
          this.topData.publishStudentIsDisplayKnowledgeScoreRate,
          this.rateCols
        );
      } else {
        cols = this.rateCols.filter((item) => item.title.indexOf("整体") == -1);
      }
      this.columns = [
        { title: "知识点", dataIndex: "knowledgeName", align: "center" },
        { title: "满分值", dataIndex: "score", align: "center" },
        { title: "得分", dataIndex: "actualScore", align: "center" },
        {
          title: "得分率",
          dataIndex: "scoreRate",
          align: "center",
          customRender: (text, record) => record.scoreRate + "%",
        },
        ...cols,
        { title: "题量", dataIndex: "countQuestion", align: "center" },
        { title: "题号", dataIndex: "questionOrders", align: "center" },
      ];
      this.tableData = res.data.data;
      this.options = {
        legend: {
          itemGap: 35,
          type: "scroll",
        },
        tooltip: {
          backgroundColor: "rgba(0,0,0,0.7)",
          borderColor: "rgba(0,0,0,0.7)",
          color: "#fff",
          textStyle: {
            color: "#fff",
          },
        },
        color: ["#76B2EE", "#FA8962"],
        radar: {
          indicator: res.data.data.map((item) => {
            return {
              name: item.knowledgeName,
              max: 100,
            };
          }),
        },
        series: [
          {
            name: "知识点",
            type: "radar",
            data: [
              {
                value: res.data.data.map((item) => item.scoreRate),
                name: "得分率",
              },
              {
                value: res.data.data.map((item) => item.gradeScoreRate),
                name: "校得分率",
              },
            ],
          },
        ],
      };
      this.tokenKey = Math.random();
      this.closeLoading(loadingInstance, io);
    },
    closeLoading(loadingInstance, io) {
      if (loadingInstance && io) {
        this.$nextTick(() => {
          loadingInstance.close();
          io.unobserve(this.$refs.kownlagePoint);
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../new-exam.module.scss";
</style>
