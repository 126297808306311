<template>
  <div class="stat-contain">
    <div class="topData">
      <div class="school-tag">{{ schoolName }}</div>
      <div class="title">
        <div class="new-exam-tag">新高考</div>
        <div class="text">{{ topData.name }}</div>
        <div :class="['sub-tag', 'general']">
          {{ getExamType(topData.type) }}
        </div>
      </div>
      <div class="sub">
        <!-- <div class="back" @click="back"><a-icon type="left" /> 返回</div> -->
        <div></div>
        <div class="date">
          <span>年级：{{ topData.year }}级</span>
          <span>考试时间：{{ topData.examDate }}</span>
        </div>
        <div></div>
      </div>
      <div class="model-title">{{ $route.query.studentName }} - 学情报告</div>
      <div class="subjectList-tabs">
        <div
          v-for="(item, index) in subjectList"
          :key="index"
          :class="['subject', subjectId == item.id ? 'subject-active' : '']"
          @click="subjectChange(item.id)"
        >
          {{ item.name }}
        </div>
      </div>
    </div>

    <template v-if="topData.year && subjectId !== null">
      <current
        id="current"
        :key="'current' + subjectId"
        :top-data="topData"
        :subject-id="subjectId"
      />
      <subject
        id="subject"
        :key="'subject' + subjectId"
        :subject-id="subjectId"
      />
      <situation
        id="situation"
        :key="'situation' + subjectId"
        :subject-id="subjectId"
      />
    </template>
    <div class="container">
      <div class="subjectList">
        <div
          v-for="(item, index) in noTotalSubjectList"
          :key="index"
          :class="[
            'subject',
            subjectIdOther == item.id ? 'subject-active' : '',
          ]"
          @click="subjectOtherChange(item.id)"
        >
          {{ item.name }}
        </div>
      </div>
    </div>

    <template v-if="topData.year && subjectIdOther !== null">
      <kownlagePoint
        v-if="
          hasKnowledge &&
          unionVisibleByParams('publishStudentIsDisplayKnowledge')
        "
        id="kownlagePoint"
        :key="'kownlagePoint' + subjectIdOther"
        :top-data="topData"
        :subject-id="subjectIdOther"
      />
      <questions
        v-if="unionVisibleByParams('publishStudentIsDisplayQuestion')"
        id="questions"
        :key="'questions' + subjectIdOther"
        :subject-id="subjectIdOther"
        :top-data="topData"
      />
    </template>
    <pageNav :menu-list="menuList" />
  </div>
</template>

<script>
import { examTypeOptions } from "@/core/util/constdata";
import { getTopData } from "@/core/api/newExamAcademic/student";
import { getstudentreporttopinfo } from "@/core/api/newExamAcademic/union/student";
import { getstatsubjectoption } from "@/core/api/academic/common";
import { unionVisibleByParams } from "../func";
import current from "./current";
import subject from "./subject";
import situation from "./situation";
import questions from "./questions";
import kownlagePoint from "./kownlagePoint";
import { getStore } from "@/core/util/store";
import pageNav from "@/views/academic/components/pageNav.vue";
export default {
  name: "",
  components: {
    current,
    subject,
    situation,
    kownlagePoint,
    questions,
    pageNav,
  },
  data() {
    return {
      topData: {
        type: 99,
      },
      unionVisibleByParams: unionVisibleByParams.bind(this),
      schoolName: null,
      subjectId: 0,
      subjectList: [{ subjectId: 0, subjectName: "全部" }],
      menuList: [
        {
          id: "current",
          name: "本次成绩",
        },
        {
          id: "subject",
          name: "优劣势学科",
        },
        {
          id: "situation",
          name: "学情跟踪",
        },
        {
          id: "questions",
          name: "题目应答情况",
        },
      ],
      noTotalSubjectList: [],
      subjectIdOther: null,
      hasKnowledge: false,
    };
  },
  mounted() {
    this.getData();
    if (this.$route.query.examType == 1) {
      this.schoolName = this.$route.query.schoolName;
    } else {
      this.schoolName = getStore({ name: "userInfo" }).schoolName;
    }
  },
  methods: {
    async getSubjectList() {
      const res = await getstatsubjectoption({
        id: this.$route.query.id,
        classNum: this.$route.query.classNum,
        hasTotal: 1,
        studentId: this.$route.query.studentId,
        requestPage: 6,
        viewSchoolId: this.$route.query.schoolId,
      });
      this.subjectList = res.data.data;
      this.noTotalSubjectList = res.data.data.filter((item) => item.id != 0);
      if (this.noTotalSubjectList.length) {
        this.subjectIdOther = this.noTotalSubjectList[0].id;
        this.hasKownlagePoint();
      }
      this.subjectId = this.$route.query.subjectId || 0;
    },
    async getData() {
      let res;
      if (this.$route.query.examType == 1) {
        res = await getstudentreporttopinfo({
          id: this.$route.query.id,
          studentId: this.$route.query.studentId,
          viewSchoolId: this.$route.query.schoolId,
        });
      } else {
        res = await getTopData({
          id: this.$route.query.id,
          studentId: this.$route.query.studentId,
        });
      }

      this.topData = res.data.data;
      if (this.$route.query.examType == 1) {
        if (this.topData.publishStudentIsDisplayQuestion != 1) {
          this.menuList = this.menuList.filter(
            (item) => item.name != "题目应答情况"
          );
        }
      }
      this.getSubjectList();
    },
    subjectChange(subjectId) {
      this.subjectId = subjectId;
    },
    subjectOtherChange(subjectId) {
      this.subjectIdOther = subjectId;
      this.hasKownlagePoint();
    },
    hasKownlagePoint() {
      let item = this.topData.subjectKnowledges.find(
        (item) => item.subjectId === this.subjectIdOther
      );

      this.hasKnowledge = item.hasKnowledge == 1;
    },
    getExamType(type) {
      if (type) {
        const data = examTypeOptions.find((item) => item.value === type);
        return data.label;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../new-exam.module.scss";

.subjectList-tabs {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-start;
  background: #ffffff;

  .subject {
    border-radius: 4px;
    border: 1px solid #d5d6db;
    color: #0a1119ff;
    font-size: 14px;
    letter-spacing: 1px;
    box-sizing: border-box;
    padding: 6px 18px;
    margin-right: 8px;
    margin-bottom: 16px;
    border-radius: 16px;
    cursor: pointer;
    border: 1px solid transparent;
  }

  .subject-active {
    background-color: #2474edff;
    color: #2474edff;
    border-color: #2474edff;
    background-color: #f3f8ff;
  }
}

.subjectList {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-start;
  background: #ffffff;
  border-radius: 8px;
  border: 1px solid #d5d6db;
  box-sizing: border-box;
  padding: 16px 16px 0 16px;

  .subject {
    height: 32px;
    border-radius: 4px;
    border: 1px solid #d5d6db;
    line-height: 32px;
    color: #0a1119ff;
    font-size: 14px;
    letter-spacing: 1px;
    box-sizing: border-box;
    padding: 0 18px;
    margin-right: 8px;
    margin-bottom: 16px;
    cursor: pointer;
  }

  .subject-active {
    background-color: #2474edff;
    color: #ffffff;
  }
}
</style>
