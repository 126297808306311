<template>
  <div class="exportBtn">
    <div class="centered" :style="{ textAlign: align }">
      <slot></slot>
    </div>
    <div
      v-if="$route.query.examType != 1"
      :class="align != 'right' ? 'right-aligned' : ''"
    >
      <a-button :type="btnType" :loading="loading" @click="openModal">{{
        text
      }}</a-button>
      <a-modal
        v-model="visible"
        title="提示"
        :confirm-loading="confirmLoading"
        @ok="handleOK"
        @cancel="handleCancel"
      >
        <div class="tips">如果有数据变动请选择“是”进行导出</div>
        是否重新生成: <a-radio-group v-model="again" :options="plainOptions" />
      </a-modal>
    </div>
  </div>
</template>
<script>
export default {
  name: "ExportBtn",
  props: {
    text: {
      type: String,
      default: "导出报表",
    },
    btnType: {
      type: String,
      default: "link",
    },
    exportUrl: {
      type: String,
      default: "",
    },
    exportName: {
      type: String,
      default: "",
    },
    requestFunc: {
      type: Function,
      default: () => {},
    },
    requestUrl: {
      type: String,
      default: "",
    },
    params: {
      type: Object,
      default: () => {},
    },
    align: {
      type: String,
      default: "center",
    },
    isBigSize: {
      // 当导出量过大时将接口url 和 payload  存入session中 下载页新增 query 参数 type='bigSize'下载页请求获取文件地址和文件名
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      visible: false,
      confirmLoading: false,
      again: 0,
      plainOptions: [
        {
          label: "是",
          value: 1,
        },
        {
          label: "否",
          value: 0,
        },
      ],
    };
  },
  created() {},
  methods: {
    handleCancel() {
      this.visible = false;
    },
    handleOK() {
      this.confirmLoading = true;
      this.exportSheet();
    },
    async exportSheet() {
      if (this.isBigSize) {
        sessionStorage.setItem(
          "downFileParams",
          JSON.stringify({
            requestUrl: this.requestUrl,
            params: this.params,
          })
        );
        setTimeout(() => {
          const { href } = this.$router.resolve({
            path: "/downFile",
            query: {
              type: "bigSize",
            },
          });
          let timer = setTimeout(() => {
            this.confirmLoading = false;
            window.open(href, "_blank");
            clearTimeout(timer);
          }, 0);
        }, 200);
      } else {
        try {
          this.loading = true;
          const res = await this.requestFunc({
            ...this.params,
            again: this.again,
          });
          const { href } = this.$router.resolve({
            path: "/downFile",
            query: {
              url: res.data.data.url,
              name: res.data.data.name,
              type: 1,
            },
          });
          let timer = setTimeout(() => {
            this.confirmLoading = false;
            window.open(href, "_blank");
            clearTimeout(timer);
          }, 0);
          this.loading = false;
        } catch {
          this.confirmLoading = false;
          this.loading = false;
        }
      }
    },
    openModal() {
      this.visible = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.exportBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 23px;
  /* 两端对齐 */
  position: relative;
}

.centered {
  /* 文字居中 */
  flex: 1;
  /* 占据剩余空间 */
}

.right-aligned {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
}
.tips {
  box-sizing: border-box;
  padding: 10px;
  color: #262829;
  font-size: 12px;
  margin-bottom: 18px;
  background-color: #f3f8ff;
  line-height: 2;
  .tips-title {
    color: #2474ed;
  }
}
</style>
