<template>
  <div class="transferComponent">
    <div class="content">
      <div class="left">
        <div class="header">
          <div class="allChecked">
            <el-button type="text" @click="handleCheckAllChange"
              >全选</el-button
            >
          </div>
          <div class="reserve">
            <el-button type="text" @click="handleCheckReserveChange"
              >反选</el-button
            >
          </div>
        </div>
        <div class="left-content">
          <el-checkbox-group v-model="checkList" @change="checkChange">
            <el-checkbox
              v-for="(item, index) in data"
              :key="index"
              :label="item.id"
            >
              <el-tooltip
                class="item"
                effect="dark"
                :content="item.name"
                placement="top"
              >
                <span>{{ item.name }}</span>
              </el-tooltip>
            </el-checkbox>
          </el-checkbox-group>
        </div>
      </div>
      <div class="right">
        <div class="right-header">
          <el-button type="text" @click="clearAll">清空</el-button>
        </div>
        <div class="right-conent">
          <div
            v-for="(item, index) in checkList"
            :key="index"
            class="right-conent-item"
          >
            <el-tooltip
              class="item"
              effect="dark"
              :content="getName(item)"
              placement="top"
            >
              <div class="title">{{ getName(item) }}</div>
            </el-tooltip>

            <i class="el-icon-close" @click="delItem(item, index)"></i>
          </div>
        </div>
      </div>
    </div>
    <div class="checkedNum">已选: {{ checkList.length }}</div>
  </div>
</template>
<script>
export default {
  props: {
    data: {
      type: Array,
      default: () => {},
    },
    value: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isIndeterminate: false,
      checkAll: false,
      reserve: false,
      checkList: [],
      storage: [],
    };
  },
  watch: {
    // checkList(val) {
    //   let data = {
    //     ids: val.toString(),
    //     names: this.data
    //       .filter((item) => {
    //         if (val.includes(item.id)) {
    //           return item;
    //         }
    //       })
    //       .map((item) => item.name)
    //       .toString(),
    //   };
    //   this.$emit("change", data);
    // },
  },
  mounted() {
    this.$nextTick(() => {
      this.checkList = [...this.value.filter((item) => item)];
    });
  },
  methods: {
    init(ids) {
      this.checkList = ids.map((item) => {
        return Number(item);
      });
    },
    getName(id) {
      if (id) {
        const data = this.data.find((item) => item.id == id);
        return data.name;
      }
    },
    handleCheckAllChange() {
      if (this.data.length) {
        this.checkList = this.data.map((item) => item.id);
      } else {
        this.$message.error("当前考试类型可选考试");
      }
    },
    handleCheckReserveChange() {
      if (this.data.length) {
        this.storage = JSON.parse(JSON.stringify(this.checkList));
        this.checkList = this.data
          .filter((item) => {
            if (!this.checkList.includes(item.id)) {
              return item;
            }
          })
          .map((item) => item.id);
      } else {
        this.$message.error("请选择考试类型");
      }
    },
    delItem(item, index) {
      this.checkList.splice(index, 1);
      this.checkAll = false;
      this.isIndeterminate = false;
    },
    checkChange(val) {
      let checkedCount = val.length;
      this.checkAll = checkedCount === this.data.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.data.length;
    },
    clearAll() {
      this.checkList = [];
      this.checkAll = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.transferComponent {
  margin-top: 24px;
  .content {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;
    .left {
      margin-right: 24px;
      width: 45%;
      height: 370px;
      display: flex;
      align-items: center;
      flex-direction: column;
      border: 1px solid #dee1e7;
      .header {
        height: 32px;
        background-color: #f5f7fa;
        display: flex;
        align-items: center;
        width: 100%;
        box-sizing: border-box;
        padding: 0 24px;
        div {
          display: flex;
          align-items: center;
        }
        .allChecked {
          margin-right: 30px;
        }
      }
      &-content {
        width: 100%;
        flex: 1;
        overflow-y: auto;
        box-sizing: border-box;
        padding: 9px 24px;
        ::v-deep .el-checkbox {
          width: 100%;
        }
        &::-webkit-scrollbar {
          width: 4px;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 10px;
          -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
          background: rgba(0, 0, 0, 0.2);
        }
        &::-webkit-scrollbar-track {
          -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
          border-radius: 0;
          background: rgba(0, 0, 0, 0.1);
        }
      }
    }
    .right {
      width: 45%;
      height: 370px;
      display: flex;
      align-items: center;
      border: 1px solid #dee1e7;
      flex-direction: column;
      .right-header {
        height: 32px;
        background-color: #f5f7fa;
        display: flex;
        justify-content: flex-end;
        width: 100%;
        box-sizing: border-box;
        padding: 0 24px;
      }
      .right-conent {
        width: 100%;
        flex: 1;
        box-sizing: border-box;
        padding: 9px 24px;
        overflow-y: auto;
        .right-conent-item {
          margin: 9px 0;
          display: flex;
          align-items: center;
          justify-content: space-between;
          color: #2474ed;
          font-size: 14px;
          .title {
            width: 90%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            text-overflow: ellipsis;
            -o-text-overflow: ellipsis;
          }
          i {
            cursor: pointer;
          }
        }
        &::-webkit-scrollbar {
          width: 4px;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 10px;
          -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
          background: rgba(0, 0, 0, 0.2);
        }
        &::-webkit-scrollbar-track {
          -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
          border-radius: 0;
          background: rgba(0, 0, 0, 0.1);
        }
      }
    }
  }
}
::v-deep.el-checkbox {
  margin: 9px 0;
  display: flex;
  align-items: center;
  height: 21px;
}
.checkedNum {
  text-align: center;
}
::v-deep .el-checkbox__label {
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
</style>
