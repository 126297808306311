var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"stat-contain"},[_c('div',{staticClass:"topData"},[_c('div',{staticClass:"school-tag"},[_vm._v(_vm._s(_vm.schoolName))]),_c('div',{staticClass:"title"},[_c('div',{staticClass:"new-exam-tag"},[_vm._v("新高考")]),_c('div',{staticClass:"text"},[_vm._v(_vm._s(_vm.topData.name))]),_c('div',{class:['sub-tag', 'general']},[_vm._v(" "+_vm._s(_vm.getExamType(_vm.topData.type))+" ")])]),_c('div',{staticClass:"sub"},[_c('div'),_c('div',{staticClass:"date"},[_c('span',[_vm._v("年级："+_vm._s(_vm.topData.year)+"级")]),_c('span',[_vm._v("考试时间："+_vm._s(_vm.topData.examDate))])]),_c('div')]),_c('div',{staticClass:"model-title"},[_vm._v(_vm._s(_vm.$route.query.studentName)+" - 学情报告")]),_c('div',{staticClass:"subjectList-tabs"},_vm._l((_vm.subjectList),function(item,index){return _c('div',{key:index,class:['subject', _vm.subjectId == item.id ? 'subject-active' : ''],on:{"click":function($event){return _vm.subjectChange(item.id)}}},[_vm._v(" "+_vm._s(item.name)+" ")])}),0)]),(_vm.topData.year && _vm.subjectId !== null)?[_c('current',{key:'current' + _vm.subjectId,attrs:{"id":"current","top-data":_vm.topData,"subject-id":_vm.subjectId}}),_c('subject',{key:'subject' + _vm.subjectId,attrs:{"id":"subject","subject-id":_vm.subjectId}}),_c('situation',{key:'situation' + _vm.subjectId,attrs:{"id":"situation","subject-id":_vm.subjectId}})]:_vm._e(),_c('div',{staticClass:"container"},[_c('div',{staticClass:"subjectList"},_vm._l((_vm.noTotalSubjectList),function(item,index){return _c('div',{key:index,class:[
          'subject',
          _vm.subjectIdOther == item.id ? 'subject-active' : '',
        ],on:{"click":function($event){return _vm.subjectOtherChange(item.id)}}},[_vm._v(" "+_vm._s(item.name)+" ")])}),0)]),(_vm.topData.year && _vm.subjectIdOther !== null)?[(
        _vm.hasKnowledge &&
        _vm.unionVisibleByParams('publishStudentIsDisplayKnowledge')
      )?_c('kownlagePoint',{key:'kownlagePoint' + _vm.subjectIdOther,attrs:{"id":"kownlagePoint","top-data":_vm.topData,"subject-id":_vm.subjectIdOther}}):_vm._e(),(_vm.unionVisibleByParams('publishStudentIsDisplayQuestion'))?_c('questions',{key:'questions' + _vm.subjectIdOther,attrs:{"id":"questions","subject-id":_vm.subjectIdOther,"top-data":_vm.topData}}):_vm._e()]:_vm._e(),_c('pageNav',{attrs:{"menu-list":_vm.menuList}})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }