import request from "@/core/services/axios";
export const getstudentreportstudentlisttopinfo = (params) => {
  // 获取学生榜单顶部数据-联考新高考
  return request({
    url: `/report/statunionnewgaokaostudentreport/getstudentreportstudentlisttopinfo`,
    method: "GET",
    params: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
};
export const unionStudentlistpage = (params) => {
  // 学生榜单分页查询-联考新高考
  return request({
    url: `/report/statunionnewgaokaostudentreport/studentlistpage`,
    method: "post",
    data: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
};
export const getstudentreporttopinfo = (params) => {
  // 获取学生报告顶部数据-联考新高考
  return request({
    url: `/report/statunionnewgaokaostudentreport/getstudentreporttopinfo`,
    method: "GET",
    params: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
};
export const unionGetsrscoreoverview = (params) => {
  // 学生报告-成绩概述-联考新高考
  return request({
    url: `/report/statunionnewgaokaostudentreport/getsrscoreoverview`,
    method: "GET",
    params: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
};
export const unionGetsrweak = (params) => {
  // 学生报告-优劣势学科-联考新高考
  return request({
    url: `/report/statunionnewgaokaostudentreport/getsrweak`,
    method: "GET",
    params: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
};
export const getsrlearningtrackstatlist = (params) => {
  // 学生报告-学情跟踪-统计列表搜索-联考新高考
  return request({
    url: `/report/statunionnewgaokaostudentreport/getsrlearningtrackstatlist`,
    method: "post",
    data: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
};
export const unionGetsrlearningtrackV2 = (params) => {
  // 学生报告-学情跟踪V2-联考新高考
  return request({
    url: `/report/statunionnewgaokaostudentreport/getsrlearningtrack/v2`,
    method: "post",
    data: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
};
export const unionExportstudentscore = (params) => {
  // 学生报告-导出学生成绩榜单-联考新高考
  return request({
    url: `/report/statunionnewgaokaostudentreport/exportstudentscore`,
    method: "GET",
    params: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
};

export const unionGetsrquestion = (params) => {
  // 学生报告-题目应答情况-联考新高考
  return request({
    url: `/report/statunionnewgaokaostudentreport/getsrquestion`,
    method: "GET",
    params: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
};
