<template>
  <div ref="current" v-lazyChart="{ fn: getData }" class="container">
    <div class="title">本次成绩</div>
    <a-table
      :columns="columns"
      :data-source="tableData"
      :pagination="false"
      bordered
      :scroll="{ x: 'max-content' }"
    >
      <template slot="schoolRankOri">
        <div>校排名</div>
        <div>(原始分)</div>
      </template>
      <template slot="schoolRank">
        <div>校排名</div>
        <div>(赋分)</div>
      </template>
      <template slot="classRankOri">
        <div>班级排名</div>
        <div>(原始分)</div>
      </template>
      <template slot="classRank">
        <div>班级排名</div>
        <div>(赋分)</div>
      </template>
      <template slot="unionRankOri">
        <div>整体排名</div>
        <div>(原始分)</div>
      </template>
      <template slot="unionRank">
        <div>整体排名</div>
        <div>(赋分)</div>
      </template>

      <template slot="cardButton" slot-scope="text, record">
        <a-button
          v-if="record.answerSheetUrl"
          type="link"
          @click="detail(record)"
          >查看</a-button
        >
        <span v-else>无答题卡</span>
      </template>
    </a-table>
  </div>
</template>

<script>
import { getsrscoreoverview } from "@/core/api/newExamAcademic/student";
import { unionGetsrscoreoverview } from "@/core/api/newExamAcademic/union/student";
import { mapState } from "vuex";
export default {
  name: "",
  props: {
    subjectId: {
      type: [String, Number],
      default: "",
    },
    topData: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    ...mapState({
      roleId: (state) => state.report.roleId,
    }),
  },
  data() {
    return {
      columns: [],
      tableData: [],
      totalCols: [
        {
          dataIndex: "subjectName",
          title: "科目",
          align: "center",
          width: 75,
        },
        {
          dataIndex: "scoreOri",
          title: "原始分",
          align: "center",
          width: 85,
        },
        {
          dataIndex: "score",
          title: "赋分",
          width: 85,
          align: "center",
          customRender: (text, reocrd) => {
            if ([1, 2, 3, 4, 8].includes(this.subjectId)) {
              if ([1, 2, 3, 4, 8].includes(reocrd.subjectId)) {
                return "-";
              } else {
                return reocrd.score;
              }
            } else {
              if ([1, 2, 3, 4, 8].includes(reocrd.subjectId)) {
                return "-";
              } else {
                return reocrd.score;
              }
            }
          },
        },
      ],
      rankCols: [
        {
          dataIndex: "classRankOri",
          slots: { title: "classRankOri" },
          width: 100,
          align: "center",
        },
        {
          dataIndex: "classRank",
          slots: { title: "classRank" },
          width: 100,
          align: "center",
          customRender: (text, reocrd) => {
            if ([1, 2, 3, 4, 8].includes(this.subjectId)) {
              if ([1, 2, 3, 4, 8].includes(reocrd.subjectId)) {
                return "-";
              } else {
                return reocrd.classRank;
              }
            } else {
              if ([1, 2, 3, 4, 8].includes(reocrd.subjectId)) {
                return "-";
              } else {
                return reocrd.classRank;
              }
            }
          },
        },
        {
          dataIndex: "schoolRankOri",
          width: 100,
          align: "center",
          slots: { title: "schoolRankOri" },
          customRender: (text, record) => {
            let schoolRankOri = record.schoolRankOri || "";
            if (record.level) {
              schoolRankOri = `${record.schoolRankOri || ""}(${record.level})`;
            }
            let classStyle = {
              1: "top5",
              2: "after5",
            };
            let className = classStyle[record.levelType];
            return <div class={className}>{schoolRankOri}</div>;
          },
          customCell: (record) => ({
            style: {
              "background-color": record.levelType == 2 ? "#FFEDED" : "",
            },
          }),
        },
        {
          dataIndex: "schoolRank",
          width: 100,
          align: "center",
          slots: { title: "schoolRank" },
          customRender: (text, reocrd) => {
            if ([1, 2, 3, 4, 8].includes(this.subjectId)) {
              if ([1, 2, 3, 4, 8].includes(reocrd.subjectId)) {
                return "-";
              } else {
                return reocrd.schoolRank;
              }
            } else {
              if ([1, 2, 3, 4, 8].includes(reocrd.subjectId)) {
                return "-";
              } else {
                return reocrd.schoolRank;
              }
            }
          },
        },
      ],
      averageCols: [
        {
          dataIndex: "classAverageScoreOri",
          title: "班级均分",
          width: 150,
          align: "center",
          customRender: (text, record) => {
            let diff = record.scoreOri - record.classAverageScoreOri;
            if (diff > 0) {
              return (
                <div>
                  {record.classAverageScoreOri}
                  <span class="diff">+{diff.toFixed(2)}</span>
                </div>
              );
            } else if (diff < 0) {
              return (
                <div>
                  {record.classAverageScoreOri}
                  <span class="diff">{diff.toFixed(2)}</span>
                </div>
              );
            } else {
              return <div>{record.classAverageScoreOri}</div>;
            }
          },
        },
        {
          dataIndex: "schoolAverageScoreOri",
          title: "校均分",
          width: 150,
          align: "center",
          customRender: (text, record) => {
            let diff = record.scoreOri - record.schoolAverageScoreOri;
            if (diff > 0) {
              return (
                <div>
                  {record.schoolAverageScoreOri}
                  <span class="diff">+{diff.toFixed(2)}</span>
                </div>
              );
            } else if (diff < 0) {
              return (
                <div>
                  {record.schoolAverageScoreOri}
                  <span class="diff">{diff.toFixed(2)}</span>
                </div>
              );
            } else {
              return <div>{record.schoolAverageScoreOri}</div>;
            }
          },
        },
      ],
      subjectCols: [
        {
          dataIndex: "weak",
          title: "薄弱情况",
          width: 100,
          align: "center",
          customRender: (text, reocrd) => {
            let obj = {
              0: "-",
              1: "稍薄弱",
              2: "严重偏科",
            };
            return obj[reocrd.weak];
          },
        },
        {
          dataIndex: "card",
          title: "答题卡",
          align: "center",
          width: 100,
          scopedSlots: { customRender: "cardButton" },
        },
      ],
    };
  },
  created() {},
  methods: {
    createCols() {
      let totalCols = [...this.totalCols];
      let rankCols = [...this.rankCols];
      let averageCols = [...this.averageCols];
      let subjectCols = [...this.subjectCols];
      if (this.$route.query.examType == 1) {
        rankCols = rankCols.concat([
          {
            align: "center",
            dataIndex: "unionRankOri",
            width: 100,
            slots: { title: "unionRankOri" },
          },
        ]);
        if (this.topData.isSchoolDisplayReportUnionAverageScore == 1) {
          averageCols = averageCols.concat([
            {
              dataIndex: "unionAverageScoreOri",
              title: "整体均分",
              width: 150,
              align: "center",
              customRender: (text, record) => {
                let diff = record.scoreOri - record.unionAverageScoreOri;
                if (diff > 0) {
                  return (
                    <div>
                      {record.unionAverageScoreOri}
                      <span class="diff">+{diff.toFixed(2)}</span>
                    </div>
                  );
                } else if (diff < 0) {
                  return (
                    <div>
                      {record.unionAverageScoreOri}
                      <span class="diff">{diff.toFixed(2)}</span>
                    </div>
                  );
                } else {
                  return <div>{record.unionAverageScoreOri}</div>;
                }
              },
            },
          ]);
        }
        if (![1, 2, 3, 4, 8].includes(this.subjectId)) {
          rankCols = rankCols.concat([
            {
              align: "center",
              dataIndex: "unionRank",
              width: 100,
              slots: { title: "unionRank" },
              customRender: (text, record) =>
                [1, 2, 3, 4, 8].includes(record.subjectId) ? "-" : text,
            },
          ]);
        } else {
          rankCols = rankCols.filter(
            (item) =>
              !["unionRank", "schoolRank", "classRank"].includes(item.dataIndex)
          );
        }
        if (this.topData.isSchoolDisplayReportUnionAverageScore != 1) {
          averageCols = averageCols.filter((item) => item.title !== "整体均分");
        }
        if (this.topData.publishSchoolIsAllowTeacherViewAnswerSheet != 1) {
          subjectCols = subjectCols.filter((item) => item.title != "答题卡");
        }
      }
      if (
        (this.$route.query.examType == 1 &&
          this.$parent.topData.isSchoolDisplayReportStudentRank == 1) ||
        (this.$route.query.examType != 1 &&
          this.$parent.topData.isDisplayRank == 1) ||
        (this.roleId == 4 &&
          this.$parent.topData.publishStudentIsDisplayRank == 1)
      ) {
        this.columns = [
          ...totalCols,
          ...rankCols,
          ...averageCols,
          ...subjectCols,
        ];
      } else {
        this.columns = [...totalCols, ...averageCols, ...subjectCols];
      }
    },
    async getData(loadingInstance, io) {
      let res;
      if (this.$route.query.examType == 1) {
        res = await unionGetsrscoreoverview({
          id: this.$route.query.id,
          studentId: this.$route.query.studentId,
          subjectId: this.subjectId,
          viewSchoolId: this.$route.query.schoolId,
        });
      } else {
        res = await getsrscoreoverview({
          id: this.$route.query.id,
          studentId: this.$route.query.studentId,
          subjectId: this.subjectId,
        }).catch(() => {
          this.closeLoading(loadingInstance, io);
        });
      }

      let main = res.data.data.filter((item) => [0].includes(item.subjectId));
      let other = res.data.data.filter((item) => ![0].includes(item.subjectId));
      this.createCols();
      if (this.subjectId == 0) {
        this.tableData = [...main, ...other];
      } else {
        this.tableData = [...other, ...main];
      }
      this.closeLoading(loadingInstance, io);
    },
    closeLoading(loadingInstance, io) {
      if (loadingInstance && io) {
        this.$nextTick(() => {
          loadingInstance.close();
          io.unobserve(this.$refs.current);
        });
      }
    },
    detail(item) {
      if (item.answerSheetUrl) {
        sessionStorage.setItem("imgList", item.answerSheetUrl);
        const routeUrl = this.$router.resolve({
          path: "/academic/card",
          query: {
            name: item.subjectName,
            statId: this.$route.query.id,
            studentId: this.$route.query.studentId,
            subjectId: item.subjectId,
          },
        });
        let timer = setTimeout(() => {
          window.open(routeUrl.href, "_blank");
          clearTimeout(timer);
        }, 0);
      } else {
        this.$message.error("无可查看答题卡");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../new-exam.module.scss";

.diff {
  font-size: 12px;
  color: hsla(0, 0%, 60%, 0.6);
}

.top5 {
  color: #2474ed;
}

.after5 {
  color: #f56c6b;
}
</style>
