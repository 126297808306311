import request from "@/core/services/axios";
export const getTopData = (params) => {
  // 获取任课教师报告顶部数据
  return request({
    url: `/report/statnewgaokaostudentreport/getstudentreporttopinfo`,
    method: "GET",
    params: params,
  });
};
export const getsrscoreoverview = (params) => {
  // 学生报告-成绩概述
  return request({
    url: `/report/statnewgaokaostudentreport/getsrscoreoverview`,
    method: "GET",
    params: params,
  });
};
export const getsrweak = (params) => {
  // 学生报告-优劣势学科
  return request({
    url: `/report/statnewgaokaostudentreport/getsrweak`,
    method: "GET",
    params: params,
  });
};
export const getsrlearningtrack = (params) => {
  // 学生报告-学情跟踪
  return request({
    url: `/report/statnewgaokaostudentreport/getsrlearningtrack/v2`,
    method: "post",
    data: params,
  });
};
export const getsrquestion = (params) => {
  // 学生报告-题目应答情况
  return request({
    url: `/report/statnewgaokaostudentreport/getsrquestion`,
    method: "GET",
    params: params,
  });
};
export const getStudentRankTopData = (params) => {
  //获取学生榜单顶部数据
  return request({
    url: `/report/statnewgaokaostudentreport/getstudentreportstudentlisttopinfo`,
    method: "GET",
    params: params,
  });
};
export const studentlistpage = (params) => {
  //学生榜单分页查询
  return request({
    url: `/report/statnewgaokaostudentreport/studentlistpage`,
    method: "post",
    data: params,
  });
};
export function newExamExportstudentscore(params) {
  // 学生报告-导出学生成绩榜单
  return request({
    url: `/report/statnewgaokaostudentreport/exportstudentscore`,
    method: "get",
    params: params,
  });
}
export function getsrknowledge(params) {
  // 学生报告-知识点
  return request({
    url: `/report/statnewgaokaostudentreport/getsrknowledge`,
    method: "get",
    params: params,
  });
}
