<template>
  <div ref="subject" v-lazyChart="{ fn: getData }" class="container">
    <div class="title">优劣势学科</div>
    <div class="chart-content">
      <div class="charts">
        <chart
          :key="chartsKey"
          :define-options="defineOptions"
          :series="series"
          :height="'350px'"
        />
      </div>
      <div class="board">
        <div class="num">共参与考试:{{ examTotal }}</div>
        <div class="box error">
          <div class="box-title">{{ astableSubject || "-" }}</div>
          <div>稳定薄弱科目</div>
        </div>
        <div class="box primary">
          <div class="box-title">{{ warnSubject || "-" }}</div>
          <div>需要注意科目</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import chart from "../../components/charts.vue";
import { getsrweak } from "@/core/api/newExamAcademic/student";
import { unionGetsrweak } from "@/core/api/newExamAcademic/union/student";
export default {
  name: "",
  components: {
    chart,
  },
  props: {
    subjectId: {
      type: [Number, String],
      default: "",
    },
  },
  data() {
    return {
      defineOptions: {},
      series: [],
      chartsKey: 1,
      astableSubject: "",
      warnSubject: "",
      examTotal: "",
    };
  },
  mounted() {},
  methods: {
    async getData(loadingInstance, io) {
      let res;
      if (this.$route.query.examType == 1) {
        res = await unionGetsrweak({
          id: this.$route.query.id,
          studentId: this.$route.query.studentId,
          viewSchoolId: this.$route.query.schoolId,
        });
      } else {
        res = await getsrweak({
          id: this.$route.query.id,
          studentId: this.$route.query.studentId,
        }).catch(() => {
          this.closeLoading(loadingInstance, io);
        });
      }

      this.astableSubject = res.data.data.stableSubject.join("、");
      this.warnSubject = res.data.data.warnSubject.join("、");
      this.examTotal = res.data.data.examTotal;
      this.initChart(res.data.data);
      this.closeLoading(loadingInstance, io);
    },
    closeLoading(loadingInstance, io) {
      if (loadingInstance && io) {
        this.$nextTick(() => {
          loadingInstance.close();
          io.unobserve(this.$refs.subject);
        });
      }
    },
    initChart(res) {
      this.defineOptions = {
        grid: {
          containLabel: true,
        },
        xAxis: {
          type: "value",
          boundaryGap: [0, 0.01],
          show: false,
        },
        color: ["#619AF1", "#FA8161D9"],
        yAxis: {
          type: "category",
          data: [...res.subjects.map((item) => item.subjectName)],
          axisTick: { show: false },
          axisLine: { show: false },
        },
      };
      this.series = [
        {
          name: "稍薄弱",
          type: "bar",
          stack: "subject",
          barMaxWidth: 40,

          label: {
            show: true,
            position: "right",
          },
          data: [
            ...res.subjects.map((item) => ({
              label: {
                show: item.littleCount > 0,
              },
              value: item.littleCount,
            })),
          ],
        },
        {
          name: "严重偏科",
          type: "bar",
          stack: "subject",
          barMaxWidth: 40,

          label: {
            show: true,
            position: "right",
          },
          data: [
            ...res.subjects.map((item) => ({
              label: {
                show: item.seriousCount > 0,
              },
              value: item.seriousCount,
            })),
          ],
        },
      ];
      this.chartsKey = Math.random();
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../new-exam.module.scss";
.chart-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 18px;
  width: 100%;
  .charts {
    width: 50%;
  }
  .board {
    width: 40%;
    .num {
      margin-bottom: 18px;
      color: #2474ed;
    }
    .box {
      width: 100%;
      box-sizing: border-box;
      padding: 18px 0;
      text-align: center;
      font-size: 14px;
      .box-title {
        font-size: 18px;
        margin-bottom: 12px;
      }
    }
    .box + .box {
      margin-top: 22px;
    }
    .error {
      background: #fff5f5;
      color: #ff3b3bff;
    }
    .primary {
      color: #2474edff;
      background: #f4f7feff;
    }
  }
}
</style>
