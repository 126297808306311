<template>
  <div ref="track" v-lazyChart="{ fn: getCompareList }" class="container">
    <div class="title" style="margin-bottom: 0">学情跟踪</div>

    <template v-if="tableData.length">
      <div class="operate">
        <exportBtn
          :request-func="requestFunc"
          align="right"
          :params="{
            id: $route.query.id,
            studentId: $route.query.studentId,
            type: 1,
            selectedIds: examIdsStr,
          }"
        >
          <a-button type="primary" ghost @click="choosen">选择考试</a-button>
        </exportBtn>
      </div>
      <exTableVue
        :columns="columns"
        :data-source="tableData"
        style="margin-bottom: 18px"
        :scroll="{ x: subjectId != 0 ? '' : 'max-content' }"
      />
      <charts
        :key="chartKey"
        :define-options="defineOptions"
        :series="series"
      />
    </template>
    <no-data v-else />
    <a-modal
      title="选择考试"
      width="45%"
      :visible="visible"
      :confirm-loading="confirmLoading"
      ok-text="确定"
      cancel-text="取消"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <div class="filter">
        选择考试：<a-select
          v-model="examTypes"
          not-found-content="暂无数据"
          mode="multiple"
          style="width: 250px"
          @change="examTypeChange"
        >
          <a-select-option
            v-for="(item, index) in examTypeOptions"
            :key="index"
            :value="item.value"
          >
            {{ item.label }}</a-select-option
          >
        </a-select>
      </div>
      <transfer-component
        v-if="examList.length"
        ref="transferComponent"
        :value="examIds"
        :data="examList"
        new-exam
        :request-page="6"
        @change="getExamId"
      />
      <no-data v-else />
    </a-modal>
  </div>
</template>

<script>
import exTableVue from "../../components/exTable.vue";
import charts from "../../components/charts.vue";
import { examTypeOptions } from "@/core/util/constdata";
import transferComponent from "@/views/academic/components/transferComponent.vue";
import { getsrlearningtrackstatlistV2 } from "@/core/api/academic/common";
import { getsrlearningtrackstatlist } from "@/core/api/newExamAcademic/union/student";
import { getsrlearningtrack } from "@/core/api/newExamAcademic/student";
import { unionGetsrlearningtrackV2 } from "@/core/api/newExamAcademic/union/student";
import { exportStudentReportNew } from "@/core/api/academic/student";
import exportBtn from "@/views/academic/components/exportBtn.vue";
export default {
  name: "",
  components: {
    exTableVue,
    charts,
    transferComponent,
    exportBtn,
  },
  props: {
    classNum: {
      type: [String, Number],
      default: "",
    },
    subjectId: {
      type: [String, Number],
      default: "",
    },
  },
  data() {
    return {
      requestFunc: exportStudentReportNew,
      columns: [],
      columnsEnmu: [
        {
          dataIndex: "examDate",
          title: "考试时间",
          align: "center",
          width: 120,
        },
        {
          dataIndex: "examName",
          title: "考试名称",
          align: "center",
          width: 150,
        },
        {
          dataIndex: "weak",
          title: this.subjectId == 0 ? "薄弱学科" : "薄弱情况",
          align: "center",
          width: 150,
          customRender: (text, record) => {
            if (this.subjectId == 0) {
              let littleSubject = record.littleSubject
                ? record.littleSubject.join("、")
                : "";
              let seriousSubject = record.seriousSubject
                ? record.seriousSubject.join("、")
                : "";
              return (
                <div>
                  {littleSubject ? <div>{littleSubject}稍薄弱</div> : ""}
                  {seriousSubject ? (
                    <div>
                      <span style="color:#fc7070">{seriousSubject}</span>
                      严重偏科
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              );
            } else {
              let obj = {
                0: "-",
                1: "稍薄弱",
                2: "严重偏科",
              };
              return obj[record.weak];
            }
          },
        },
      ],
      examTypeOptions: examTypeOptions,
      tableData: [],
      defineOptions: {},
      series: [],
      chartKey: 1,
      visible: false,
      confirmLoading: false,
      examIds: null,
      examList: [],
      examTypes: [],
      examIdsStr: "",
    };
  },
  mounted() {},
  methods: {
    examTypeChange() {
      this.getCompareList();
    },
    async getData() {
      this.tableData = [];
      let res;
      if (this.$route.query.examType == 1) {
        res = await unionGetsrlearningtrackV2({
          statId: this.$route.query.id,
          current: 1,
          size: 9999,
          studentId: this.$route.query.studentId,
          subjectId: this.subjectId,
          selectedIds: this.examIdsStr,
          viewSchoolId: this.$route.query.schoolId,
        });
        this.setUnionData(res);
      } else {
        res = await getsrlearningtrack({
          statId: this.$route.query.id,
          current: 1,
          size: 9999,
          studentId: this.$route.query.studentId,
          subjectId: this.subjectId,
          selectedIds: this.examIdsStr,
        });
        this.setOriginData(res);
      }
    },
    setOriginData(res) {
      let subjects = [];
      if (res.data.data.length) {
        subjects = res.data.data[0].subjects;
      }
      if (this.subjectId == 0) {
        let cols = subjects.flatMap((item, index) => [
          {
            title: item.subjectName,
            dataIndex: item.subjectId + "score",
            align: "center",
            width: 85,
            customRender: (text, record) => record.subjects[index].score,
          },
          {
            title: item.subjectName + "排名",
            dataIndex: item.subjectId + "rank",
            width: 100,
            align: "center",
            customRender: (text, record) => record.subjects[index].rank,
          },
        ]);
        this.columns = [...this.columnsEnmu, ...cols];
      } else {
        let cols = [
          {
            title: "得分",
            dataIndex: "score",
            width: 75,
            align: "center",
          },
          {
            title: "校排名",
            dataIndex: "rank",
            width: 75,
            align: "center",
          },
          {
            title: "班级排名",
            dataIndex: "classRank",
            width: 75,
            align: "center",
          },
        ];
        this.columns = [...this.columnsEnmu, ...cols];
      }
      this.tableData = res.data.data;
      if (res.data.data.length) {
        this.initCharts(res);
      }
    },
    setUnionData(res) {
      this.tableData = res.data.data;
      if (res.data.data.length) {
        if (this.subjectId == 0) {
          const subjects = res.data.data[0].subjects;
          let cols = subjects.map((item) => {
            let children = [];
            if ([1, 2, 3, 4, 8].includes(item.subjectId)) {
              children = [
                {
                  title: "得分",
                  dataIndex: item.subjectId + "scoreOri",
                  align: "center",
                  customRender: (text, record) => {
                    let subject = record.subjects.find(
                      (it) => it.subjectId === item.subjectId
                    );
                    return subject.score;
                  },
                },
              ];
              if (this.$parent.topData.isSchoolDisplayReportStudentRank == 1) {
                children = children.concat([
                  {
                    title: "校排名",
                    dataIndex: item.subjectId + "schoolRankOri",
                    align: "center",
                    customRender: (text, record) => {
                      let subject = record.subjects.find(
                        (it) => it.subjectId === item.subjectId
                      );
                      return subject.schoolRankOri;
                    },
                  },
                  {
                    title: "整体排名",
                    dataIndex: item.subjectId + "unionRankOri",
                    align: "center",
                    customRender: (text, record) => {
                      let subject = record.subjects.find(
                        (it) => it.subjectId === item.subjectId
                      );
                      return subject.unionRankOri;
                    },
                  },
                ]);
              }
            } else {
              children = [
                {
                  title: "原始分",
                  dataIndex: item.subjectId + "scoreOri",
                  align: "center",
                  customRender: (text, record) => {
                    let subject = record.subjects.find(
                      (it) => it.subjectId === item.subjectId
                    );
                    return subject.scoreOri;
                  },
                },
              ];
              if (this.$parent.topData.isSchoolDisplayReportStudentRank == 1) {
                children = children.concat([
                  {
                    slots: { title: "studentUnionRankOri" },
                    dataIndex: item.subjectId + "unionRankOri",
                    align: "center",
                    customRender: (text, record) => {
                      let subject = record.subjects.find(
                        (it) => it.subjectId === item.subjectId
                      );
                      return subject.unionRankOri;
                    },
                  },
                  {
                    slots: { title: "studentSchoolRankOri" },
                    dataIndex: item.subjectId + "schoolRankOri",
                    align: "center",
                    customRender: (text, record) => {
                      let subject = record.subjects.find(
                        (it) => it.subjectId === item.subjectId
                      );
                      return subject.schoolRankOri;
                    },
                  },
                ]);
              }
            }
            if (this.$parent.topData.isSchoolDisplayReportStudentRank != 1) {
              children = children.filter(
                (item) =>
                  ![
                    "unionRankOri",
                    "unionRank",
                    "schoolRank",
                    "schoolRankOri",
                  ].includes(item.dataIndex)
              );
            }
            return {
              title: item.subjectName,
              dataIndex: item.subjectName,
              align: "center",
              children: children,
            };
          });
          this.columns = [...this.columnsEnmu, ...cols];
        } else {
          let cols = [
            {
              title: "得分",
              dataIndex: "score",
              align: "center",
            },
          ];
          if (this.$parent.topData.isSchoolDisplayReportStudentRank == 1) {
            cols = [
              ...cols,
              {
                title: "整体排名",
                dataIndex: "unionRank",
                align: "center",
              },
              {
                title: "校排名",
                dataIndex: "schoolRank",
                align: "center",
              },
              {
                title: "班级排名",
                dataIndex: "classRank",
                align: "center",
              },
            ];
          }
          this.columns = [...this.columnsEnmu, ...cols];
        }
        this.initCharts(res);
      }
    },
    async getCompareList(loadingInstance, io) {
      let res;
      if (this.$route.query.examType == 1) {
        res = await getsrlearningtrackstatlist({
          classNum: this.$route.query.classNum,
          examLevel: this.$route.query.level,
          examTypes: this.examTypes.length ? this.examTypes.toString() : null,
          studentId: this.$route.query.studentId,
          statId: this.$route.query.id,
          subjectId: this.subjectId,
          viewSchoolId: this.$route.query.schoolId,
        });
      } else {
        res = await getsrlearningtrackstatlistV2({
          classNum: this.$route.query.classNum,
          examLevel: this.$route.query.level,
          examTypes: this.examTypes.length ? this.examTypes.toString() : null,
          studentId: this.$route.query.studentId,
          statId: this.$route.query.id,
          subjectId: this.subjectId,
        }).catch(() => {
          this.closeLoading(loadingInstance, io);
        });
      }

      this.examList = res.data.data;
      this.examIds = res.data.data.map((item) => item.id);
      this.examIdsStr = this.examIds.length ? this.examIds.toString() : "";
      if (this.examIds.length) {
        this.getData();
      }
      this.closeLoading(loadingInstance, io);
    },
    closeLoading(loadingInstance, io) {
      if (loadingInstance && io) {
        this.$nextTick(() => {
          loadingInstance.close();
          io.unobserve(this.$refs.track);
        });
      }
    },
    choosen() {
      this.visible = true;
    },
    getExamId(val) {
      this.examIdsStr = val.ids;
    },
    handleOk() {
      this.examIdsStr = this.$refs.transferComponent.checkList.join(",");
      if (this.examIdsStr) {
        this.getData();
        this.visible = false;
      } else {
        this.$message({
          type: "warning",
          message: "请选择对比考试",
          showClose: true,
        });
      }
    },
    handleCancel() {
      let ids = this.examIdsStr.split(",");
      this.$refs.transferComponent.init(ids);
      this.visible = false;
    },
    initCharts(res) {
      let arr = [...res.data.data]; // 2024/09/02 测试冯参提交优化，表格顺序和图表顺序一致（原图表顺序是 旧=>新）
      let reverseData = arr;
      if (this.subjectId == 0) {
        const xAxis = reverseData.map((item) => item.examName);
        const subjects = reverseData.length ? reverseData[0].subjects : [];
        const arr = reverseData.flatMap((item) => item.subjects);

        const data = subjects.map((item) => {
          const same = arr.filter((it) => it.subjectName === item.subjectName);

          return {
            name: item.subjectName,
            type: "line",
            data: same.map((it) => it.scoreStd),
          };
        });
        this.defineOptions = {
          legend: {
            selected: {},
          },
          tooltip: {
            backgroundColor: "rgba(0,0,0,0.7)",
            borderColor: "rgba(0,0,0,0.7)",
            textStyle: {
              color: "#fff",
            },
            trigger: "item",
            axisPointer: {
              type: "line",
              crossStyle: {
                color: "rgba(0, 0, 0, 0.1)",
              },
            },
            grid: {
              bottom: xAxis.length > 5 ? "10%" : "5%",
            },
            dataZoom: [
              //滚动条
              {
                show: xAxis.length > 5,
                type: "slider",
                realtime: true,
                startValue: 0, // 重点
                // 重点-dataX.length表示x轴数据长度
                endValue: xAxis.length - (xAxis.length - 5),
                xAxisIndex: [0],
                bottom: "10px",
                left: "center",
              },
            ],
            formatter: (params) => {
              return (
                params.name +
                "<br />" +
                params.marker +
                params.seriesName +
                "：" +
                params.value
              );
            },
          },
          xAxis: {
            type: "category",
            boundaryGap: false,
            data: xAxis,
            axisLabel: {
              show: true,
              textStyle: {
                color: "rgba(0, 0, 0, 0.45)",
              },
              formatter: (val) => {
                let arr = val.split("");
                let index = 0;
                let newArray = [];
                while (index < arr.length) {
                  let data = arr.slice(index, (index += 7));
                  newArray.push(data.join(""));
                }
                let str = newArray.reduce((str, item) => str + "\n" + item, "");
                return str;
              },
            },
            axisTick: {
              show: false,
            },
          },
          color: [
            "#6E94DB",
            "#FFC761",
            "#CE98E8",
            "#98DEE8",
            "#E89898",
            "#91AEFF",
          ],
          yAxis: {
            name: "击败率",
            type: "value",
          },
        };
        this.series = [...data];
        let selected = {};
        for (let i = 0; i < subjects.length; i++) {
          let key = subjects[i].subjectName;
          if (key !== "总分") {
            selected[key] = false;
          } else {
            selected[key] = true;
          }
        }
        this.defineOptions.legend.selected = selected;
      } else {
        const xAxis = reverseData.map((item) => item.examName);
        this.defineOptions = {
          tooltip: {
            backgroundColor: "rgba(0,0,0,0.7)",
            borderColor: "rgba(0,0,0,0.7)",
            textStyle: {
              color: "#fff",
            },
            axisPointer: {
              type: "line",
              crossStyle: {
                color: "rgba(0, 0, 0, 0.1)",
              },
            },
            grid: {
              bottom: xAxis.length > 5 ? "10%" : "5%",
            },
            dataZoom: [
              //滚动条
              {
                show: xAxis.length > 5,
                type: "slider",
                realtime: true,
                startValue: 0, // 重点
                // 重点-dataX.length表示x轴数据长度
                endValue: xAxis.length - (xAxis.length - 5),
                xAxisIndex: [0],
                bottom: "10px",
                left: "center",
              },
            ],
            formatter: (params) => {
              let str =
                params.data.examName +
                "<br />" +
                "时间：" +
                params.data.examDate +
                "<br />" +
                "分值：" +
                params.data.scoreTotal +
                "<br />" +
                "得分：" +
                params.data.score +
                "<br />" +
                "班级排名：" +
                params.data.classRank +
                "<br />" +
                "校排名：" +
                params.data.rank +
                "<br />" +
                "击败率：" +
                params.data.value;
              return str;
            },
          },
          xAxis: {
            type: "category",
            boundaryGap: false,
            data: xAxis,
            axisTick: {
              show: false,
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: "rgba(0, 0, 0, 0.45)",
              },
              formatter: (val) => {
                let arr = val.split("");
                let index = 0;
                let newArray = [];
                while (index < arr.length) {
                  let data = arr.slice(index, (index += 7));
                  newArray.push(data.join(""));
                }
                let str = newArray.reduce((str, item) => str + "\n" + item, "");
                return str;
              },
            },
          },
          color: ["#7AB5EF", "#FF9776", "#6EDBA7", "#FFD766"],
          yAxis: {
            name: "击败率",
            type: "value",
          },
        };
        this.series = [
          {
            data: reverseData.map((item) => {
              return {
                ...item,
                value: item.scoreStd || 0,
              };
            }),
            type: "line",
          },
        ];
      }

      this.chartKey = Math.random();
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../new-exam.module.scss";

.operate {
  text-align: right;
  margin-bottom: 18px;
}
</style>
