<template>
  <div ref="question" v-lazyChart="{ fn: getData }" class="container">
    <exportBtn
      :request-func="requestFunc"
      align="left"
      :params="{
        id: $route.query.id,
        studentId: $route.query.studentId,
        type: 2,
      }"
    >
      <div class="sub-title">题目应答情况</div>
    </exportBtn>
    <template v-if="tableData.length">
      <chart
        :key="chartKey"
        :define-options="defineOptions"
        :series="series"
        :data-zoom="15"
        :height="'450px'"
      />
      <exTable
        :key="'exTable' + tableDataKey"
        :columns="columns"
        :data-source="tableData"
      />
    </template>
    <no-data v-else />
    <div class="tip" style="margin-top: 18px">
      注：标红的表格表示学生得分率低于班级得分率（或校得分率或整体得分率）
    </div>
  </div>
</template>

<script>
import chart from "../../components/charts.vue";
import exTable from "../../components/exTable.vue";
import { getsrquestion } from "@/core/api/newExamAcademic/student";
import exportBtn from "@/views/academic/components/exportBtn.vue";
import { exportStudentReportNew } from "@/core/api/academic/student";
import { unionGetsrquestion } from "@/core/api/newExamAcademic/union/student";
import { columnbyParams } from "../../func";
export default {
  name: "",
  components: {
    chart,
    exTable,
    exportBtn,
  },
  props: {
    subjectId: {
      type: [String, Number],
      default: "",
    },
    topData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      requestFunc: exportStudentReportNew,
      defineOptions: {},
      series: [],
      chartKey: null,
      question: undefined,
      questionsType: [],
      columnsEnmu: [
        {
          dataIndex: "questionOrder",
          title: "题目",
          align: "center",
          width: 100,
        },
        {
          dataIndex: "score",
          title: "满分分值",
          align: "center",
        },
        {
          dataIndex: "rightAnswer",
          title: "正确答案",
          align: "center",
        },
        {
          dataIndex: "actualScore",
          title: "得分",
          align: "center",
        },
        {
          dataIndex: "scoreRate",
          title: "得分率",
          align: "center",
          customRender: (text, record) => record.scoreRate + "%",
        },
      ],
      rateCols: [
        {
          dataIndex: "classScoreRate",
          title: "得分率（班级）",
          align: "center",
          customRender: (text, record) =>
            record.classScoreRate !== null ? record.classScoreRate + "%" : "",
          customCell: (record) => ({
            style: {
              "background-color": record.isLowClass == 1 ? "#dc5b49" : "",
            },
          }),
        },
        {
          dataIndex: "gradeScoreRate",
          title: "得分率（年级）",
          align: "center",
          customRender: (text, record) =>
            record.gradeScoreRate !== null ? record.gradeScoreRate + "%" : "",
          customCell: (record) => ({
            style: {
              "background-color": record.isLowGrade == 1 ? "#dc5b49" : "",
            },
          }),
        },
        {
          dataIndex: "unionScoreRate",
          title: "得分率（整体）",
          align: "center",
          customRender: (text, record) =>
            record.unionScoreRate !== null ? record.unionScoreRate + "%" : "",
          customCell: (record) => ({
            style: {
              "background-color": record.isLowGrade == 1 ? "#dc5b49" : "",
            },
          }),
        },
      ],
      maxCols: [
        {
          dataIndex: "gradeMaxScore",
          title: "最高分（年级）",
          align: "center",
        },
        {
          dataIndex: "unionMaxScore",
          title: "最高分（整体）",
          align: "center",
        },
      ],
      toalNumCols: [
        {
          dataIndex: "gradeCountRight",
          title: "满分人数（年级）",
          align: "center",
        },
        {
          dataIndex: "unionCountRight",
          title: "满分人数（整体）",
          align: "center",
        },
      ],
      tableData: [],
      tableDataKey: Math.random(),
    };
  },
  mounted() {},
  methods: {
    async getData(loadingInstance, io) {
      let res;
      if (this.$route.query.examType == 1) {
        res = await unionGetsrquestion({
          id: this.$route.query.id,
          studentId: this.$route.query.studentId,
          subjectId: this.subjectId,
          viewSchoolId: this.$route.query.schoolId,
        });
      } else {
        res = await getsrquestion({
          id: this.$route.query.id,
          studentId: this.$route.query.studentId,
          subjectId: this.subjectId,
        }).catch(() => {
          this.closeLoading(loadingInstance, io);
        });
      }

      let rateCols = [],
        maxCols = [],
        toalNumCols = [];
      if (this.$route.query.examType != 1) {
        rateCols = [
          ...this.rateCols.filter((item) => item.title.indexOf("整体") == -1),
        ];
        maxCols = [
          ...this.maxCols.filter((item) => item.title.indexOf("整体") == -1),
        ];
        toalNumCols = [
          ...this.toalNumCols.filter(
            (item) => item.title.indexOf("整体") == -1
          ),
        ];
      } else {
        rateCols = columnbyParams(
          this.topData.publishStudentIsDisplayQuestionScoreRate,
          this.rateCols
        );
        maxCols = columnbyParams(
          this.topData.publishStudentIsDisplayQuestionHighestScore,
          this.maxCols
        );
        toalNumCols = columnbyParams(
          this.topData.publishStudentIsDisplayQuestionCountRight,
          this.toalNumCols
        );
      }
      this.columns = [
        ...this.columnsEnmu,
        ...rateCols,
        ...maxCols,
        ...toalNumCols,
      ];
      this.tableData = res.data.data;
      this.tableDataKey = Math.random();
      if (this.tableData.length) {
        this.initChart(res.data.data);
      }

      this.closeLoading(loadingInstance, io);
    },
    closeLoading(loadingInstance, io) {
      if (loadingInstance && io) {
        this.$nextTick(() => {
          loadingInstance.close();
          io.unobserve(this.$refs.question);
        });
      }
    },
    initChart(data) {
      this.defineOptions = {
        color: ["#619AF1", "#FF743AD9", "#7C1FE7FF"],
        title: {
          subtext: "图. 得分率统计",
          subtextStyle: {
            align: "center",
          },
          left: "center",
          top: "5%",
        },
        grid: {
          top: "15%",
        },
        xAxis: {
          type: "category",
          axisTick: {
            show: false,
          },
          axisLabel: {
            show: true,
            interval: 0,
            width: 100,
            overflow: "breakAll",
          },
          data: [...data.map((item) => item.questionOrder)],
        },
      };
      this.series = [
        {
          name: "得分率",
          type: "bar",
          barMaxWidth: 40,

          label: {
            position: "top",
            show: true,
            color: "inherit",
          },
          data: [...data.map((item) => item.scoreRate)],
        },
        {
          name: this.$route.query.examType == 1 ? "学校" : "年级",
          type: "line",
          smooth: true,
          label: {
            position: "top",
            show: true,
            color: "inherit",
          },
          data: [...data.map((item) => item.gradeScoreRate)],
        },
        {
          name: "班级",
          type: "line",
          smooth: true,
          label: {
            position: "top",
            show: true,
            color: "inherit",
          },
          data: [...data.map((item) => item.classScoreRate)],
        },
      ];
      if (this.$parent.topData.isSchoolDisplayReportUnionAverageScore == 1) {
        this.series.push({
          name: "整体",
          type: "line",
          smooth: true,
          label: {
            position: "top",
            show: true,
            color: "inherit",
          },
          data: [...data.map((item) => item.unionScoreRate)],
        });
      }
      this.chartKey = Math.random();
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../new-exam.module.scss";

.filter {
  margin-bottom: 18px;
}
</style>
